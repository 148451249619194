.donationsContainer {
    width: 80%;
    margin: 50px auto;
    text-align: center;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

p {
    font-size: 18px;
    margin-bottom: 30px;
}

.donateButton {
    padding: 10px 25px;
    font-size: 18px;
    background-color: #007BFF;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color 0.3s;
}

.donateButton:hover {
    background-color: #0056b3;
}
