.event-details {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.event-header {
  display: flex; /* Set to flex */
  align-items: center; /* Align vertically centered */
}

.event-image {
  flex: 1; /* Takes up 1 part of the flex container */
  max-width: 50%; /* Limit width */
  border-radius: 4px;
  margin-right: 2rem; /* Space between image and details */
}

.event-info {
  flex: 2; /* Takes up 2 parts of the flex container */
}

.event-time, .event-location, .event-organizer, .event-inquiries {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #555;
}

.tickets-section {
  margin-top: 2rem;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
}

.ticket-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.ticket-item:last-child {
  border-bottom: none;
}

.about-section {
  margin-top: 2rem;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
}


.tickets-section {
  margin-top: 2rem;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.ticket-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.ticket-info {
  flex: 2;
}

.quick-buy {
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  align-self: center;
}
.ticket-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.ticket-quantity button {
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
}

.ticket-quantity button:hover {
  background-color: #e0e0e0;
}

.ticket-quantity input {
  width: 50px;
  text-align: center;
  border: 1px solid #e0e0e0;
  padding: 5px;
  margin: 0 10px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .ticket-quantity button {
    padding: 5px 8px;
  }

  .ticket-quantity input {
    width: 40px;
  }
}
