.blogContainer .sidebar {
    min-width: 100px;
    max-width: 300px;
    height: 90vh;
    background-color: #000;
    position: fixed;
    top: 90px;
    left: 0;
    overflow-x: hidden;
    transition: max-width 0.3s ease-in-out;
}

.sidebar.collapsed {
    max-width: 60px;
}

.blogContainer .collapsed-content, .blogContainer .expanded-content .expanded-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  /* Center content vertically */
    height: 100%;
    padding: 20px 0;
}

.collapsed-content span, .expanded-content span {
    cursor: pointer;
    font-size: 50px;
    color: #fff;
    margin-bottom: 30px;
    position: fixed;      /* Make the button fixed */
    top: 110px;            /* Position from the top, adjust as necessary */
    left: 20px;           /* Position from the left, adjust as necessary */
    z-index: 1000;   
}

.collapsed-content p {
    cursor: pointer;
    color: #fff;
    white-space: nowrap;  /* Ensure "VIEW ALL" doesn't wrap */
    transform: rotate(270deg); /* Rotate the text 90 degrees */
    transform-origin: center; /* Ensures rotation happens around the center of the element */
    margin-top: 20px; /* Add some margin to push the text down slightly */
}


.category {
    margin-bottom: 15px;
    width: 100%;  /* Use full width of sidebar */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.category img {
    width: 70%; /* adjust as needed */
    height: auto;
    margin-top: 15px;
    margin-left: 30px;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.blogContainer .expanded-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.blogContainer .expanded-content {
    overflow-y: hidden;  /* This will hide the vertical scrollbar */
}

.category {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;  /* Set to relative */
}

.category p {
    color: #fff;
    position: absolute;  /* Position the title absolutely */
    bottom: 5px;  /* Adjust as needed to position at the bottom */
    left: 50%;
    transform: translateX(-50%);  /* Center the title horizontally */
    margin-left: 15px;
    font-size: 20px ;
}

/* Media query for devices with screen width less than or equal to 768px */
@media (max-width: 768px) {

    .blogContainer .sidebar {
      min-width: 100%;   /* Takes up full width of the viewport */
      max-width: 100%;   /* Takes up full width of the viewport */
      height: auto;      /* Automatic height based on content */
      top: 110px;            /* Position to the very top */
      background-color: #000;
      overflow-x: hidden;
      overflow-y: auto;  /* Allow vertical scrolling if necessary */
      transition: none;  /* No transition for mobile for smoother UX */
    }
  
    .sidebar.collapsed {
      max-width: 100%;  /* Takes up full width of the viewport */
    }
  
    .collapsed-content span, .expanded-content span {
      top: 120px; /* Adjust the position of the button for mobile */
      left: 210px;
    }
  
    .category img {
      margin-top: 15px;
      margin-left: 0; /* Reset margin for mobile */
    }
  
    .category p {
      bottom: 15px;  /* Adjust the position of the title for mobile */
      left: 50%;
      font-size: 16px; /* You can adjust the size for mobile if necessary */
      top: 110px;            /* Position to the very top */
    }
  
    .collapsed-content p {
        transform: none;  /* Remove the rotation */
        white-space: normal;  /* Allow wrapping to multiple lines */
        margin-top: 0;  /* Reset margin-top */
        text-align: center;  /* Center the text */
        margin-left: 230px;
    }

}